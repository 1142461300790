.profileForm {
    margin-top: 50px;
}
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 999; /* Ensure it's above other content */
  }
  
  .loader-wrapper {
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
  }
  .forms  .section{
    margin: 20px 0px;
  }
  .forms .row {
    margin-left: 0px;
    margin-top: 15px;;
  }
  .forms label {
    font-weight: 500 !important;
  }
  .react-datepicker-wrapper{
    display: unset !important;
  }