.react-tagsinput-tag a,
.react-tagsinput-tag a::before {
    text-decoration: none; /* Remove underline */
}
  .react-tagsinput-tag a::before {
    content: "×";
    color: white;
    text-decoration: none;
    padding-left: 2px;
    color: hsl(0, 0%, 20%); /* Bootstrap's text color on primary background */

  }
  

  
  /* Bootstrap-like styling for react-tagsinput */

/* Container */
.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ced4da; /* Bootstrap's default border color */
    border-radius: 0.25rem; /* Bootstrap's default border radius */
    /* padding: 0.375rem 0.75rem; Bootstrap's default padding */
  }
  
  /* Focused state */
  .react-tagsinput--focused {
    border-color: #80bdff; /* Bootstrap's default focused border color */
  }
  
  /* Tag */
  .react-tagsinput-tag {
    background-color: #e6e6e6; /* Bootstrap's primary color */
    border-radius: 0.25rem; /* Bootstrap's default border radius */
    color: hsl(0, 0%, 20%); /* Bootstrap's text color on primary background */
    display: inline-block;
    font-family: inherit; /* Use the default font-family */
    font-size: 89%; /* Bootstrap's default font size */
    /* font-weight: 400; Bootstrap's default font weight */
    margin-bottom: 0.5rem; /* Bootstrap's default margin */
    margin-right: 2px;
    margin-left: 6px;
    padding: 0px 6px 0px 7px;
  }
  
  /* Remove button */
  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
  }
  
  /* Input */
  .react-tagsinput-input {
    background: transparent;
    border: none;
    color: #495057; /* Bootstrap's text color */
    font-family: inherit; /* Use the default font-family */
    font-size: 1rem; /* Bootstrap's default font size */
    font-weight: 400; /* Bootstrap's default font weight */
    margin-bottom: 0.5rem; /* Bootstrap's default margin */
    margin-top: 0.125rem; /* Bootstrap's default margin */
    outline: none;
    padding: 7px 0.75rem 0px 0.75rem; /* Bootstrap's default padding */
    width: auto; /* Remove fixed width */
  }
  