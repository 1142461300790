.app-container {
	min-height: 350px;
}

/* .invalid-feedback {
	color: "#dc3545";
	margin-top: ".25rem";
	font-size: ".875em";
    background: transparent !important;
} */
.invalid-feedback {
	margin-top: 0 !important;
	margin-bottom: 12px;
	margin-top: -6px !important;
}
.kUMlDB {
	font-size: 0.813rem !important;
	font-weight: 500 !important;
}
.fxvrKk {
	font-size: 0.84rem !important;
}
.bordered-table {
	border-collapse: collapse;
}
.generate-btn{
	color: #fff;
	background: linear-gradient(to bottom right, var(--primary-color) 0, var(--primary06) 100%);
	/*border-start-start-radius: 0;*/
	/*border-start-end-radius: 60px;*/
	/*border-end-end-radius: 60px;*/
	/*border-end-start-radius: 0;*/
	box-shadow: 0 7px 12px 0 var(--primary02);
}
/* DataTableStyles.css */

/* Override default behavior to show sort icon always */
.react-data-table-component .rdt_Table th .rdt-cell {
	position: relative;
	padding-right: 20px; /* Adjust as needed */
}

.react-data-table-component .rdt_Table th .rdt-cell::after {
	position: absolute;
	top: 50%;
	right: 5px; /* Adjust as needed */
	transform: translateY(-50%);
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size: 12px; /* Adjust as needed */
	content: "\f0dc"; /* Unicode for fa-sort icon */
	pointer-events: none;
}

/* Additional styling for ascending and descending icons */
.react-data-table-component .rdt_Table th .sort-icon-up::after {
	content: "\f0de"; /* Unicode for fa-sort-up icon */
}

.react-data-table-component .rdt_Table th .sort-icon-down::after {
	content: "\f0dd"; /* Unicode for fa-sort-down icon */
}

.card-with-button .card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
